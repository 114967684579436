import './Footer.css';

const Footer = () => (
  <footer className='footer'>
    <a
      target='_blank'
      rel='noreferrer'
      href='https://github.com/srishtisuri/personal-portfolio'
      className='link footer__link'
    >
      Developed with ❤
    </a>
  </footer>
);

export default Footer;
